.my-sm {
  margin: 10px 0;
}

.my-md {
  margin: 30px 0;
}

.mr-sm {
  margin-right: 8px;
}

.mb-md {
  margin-bottom: 30px;
}

.mb-sm {
  margin-bottom: 10px;
}

.p-sm {
  padding: 10px;
}
